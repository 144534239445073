/*
 * @Date: 2023-06-19 14:19:20
 */
const Inter1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939068",
    space_js: "//xfzpdb.aimovetech.com/production/t-o-nwgwu/production/n/static/q.js"
  }
];

const NativeLg1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939069",
    space_js: "//xfzpdb.aimovetech.com/common/up/ox/source/h/x/vox.js"
  }
];

const NativeLg2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939070",
    space_js: "//xfzpdb.aimovetech.com/source/m/hgpz_pn_i/common/n.js"
  }
];

const NativeSm1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6939071",
    space_js: "//xfzpdb.aimovetech.com/site/ni_h_qaq/static/oj_r.js"
  }
];

/** 广告位配置 */
export const SPACE_MAP = {
  Home_inter: Inter1, // 插屏
  // Home_dialog: HOMEDIALOG, // 自定义插屏
  Home_top: NativeLg1, // 首页信息流-顶部
  Home_center: NativeLg2, // 首页信息流-中部
  Home_banner: NativeSm1, // 首页Banner
  Poet_top: NativeLg1, //诗人详情信息流-顶部
  Poet_center: NativeLg2, // 诗人详情信息流-中部
  Poet_banner: NativeSm1, // 诗人详情banner
  Poem_top: NativeLg1, //诗词详情信息流-顶部
  Poem_center: NativeLg2, // 诗词详情信息流-中部
  Poem_banner: NativeSm1, // 诗词详情banner
  Poem_inter: Inter1, // 诗词详情官方插屏
  PoetList_center: NativeLg2, // 诗人列表信息流
  PoetList_banner: NativeSm1 // 诗人列表banner
};
